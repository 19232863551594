<template>
	<div :class="themeClass" class="contBox margin-t-10" v-loading="pageLoading">
		<div class="newPersonbtn-Boxx">
			<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13">我的员工</div>
		</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addCarForm" :rules="rules" ref="addCarForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>
				<el-form-item label="是否启用" prop="fStatus">
					<el-switch v-model="addCarForm.fStatus"></el-switch>
				</el-form-item>
				<el-form-item label="姓名" prop="fEmployeeName">
					<el-input v-model="addCarForm.fEmployeeName" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="手机号码" prop="fMobilephone">
					<el-input v-model="addCarForm.fMobilephone" placeholder="请输入手机号码"></el-input>
				</el-form-item>
				<el-form-item label="验证码"  prop="codes">
					<div v-show="innerVisibles"  style="position: absolute;bottom: 5px;z-index: 999;padding: 3px;background-color: rgb(255, 255, 255);left: -1px;width: 324px;">
						<i  class="el-icon-circle-close" style="position: absolute;right: 14px;top: 6px;color: rgb(255 255 255);font-size: 26px;z-index: 2;background: #525252;border-radius: 50%;" @click="closeYanzheng"></i>
						<changeGraphic1 @messageData = 'messageData'></changeGraphic1>
					</div>
					<el-input v-model="addCarForm.codes" type="text" placeholder="输入验证码">
						<template slot="append">
							<div @click="getCode" v-if="verCodeText" class="pointer" style="color: #0173fe;border-radius: none !important;">获取验证码</div>
							<div v-if="!verCodeText" style="color: #0173fe">重新获取（{{verCodeNub}}）秒</div>
						</template>
					</el-input>
				</el-form-item>
				<el-form-item v-if="this.pageTitle == '新增员工信息'" label="登录密码"  prop="fPassWord">
					<el-input v-model.trim="addCarForm.fPassWord" autocomplete="new-password"  type='text' placeholder="请输入6-20位数字、字母或符号的组合"></el-input>
				</el-form-item>

				<el-form-item v-else label="登录密码" prop="fPassWord">
					<el-input v-model.trim="addCarForm.fPassWord" autocomplete="new-password" type='text' placeholder="请输入6-20位数字、字母或符号的组合"></el-input>
				</el-form-item>
				<el-form-item label="岗位" prop="fPositionIDs">
					<el-select v-model="addCarForm.fPositionIDs" clearable multiple placeholder="请选择" @change="handlestationType">
						<el-option v-for="item in stationList" :key="item.fPositionID" :label="item.fPositionName" :value="item.fPositionID">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<!-- <el-button class="color-theme border-theme" @click="resetForm('addCarForm')">取消</el-button> -->
					<el-button class="background-color-theme" @click="submitForm('addCarForm')">保存</el-button>
				</el-form-item>
			</el-form>
			<div class="bg-box"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	import IdentityCodeValid   from '@/utils/checkIdent';
	import changeGraphic1   from '@/components/checkpeople';
	export default {
		data() {
			var checkMaximum = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('重量不能为空'));
				}
				setTimeout(() => {
					if (value < 10) {
						callback(new Error('重量必须大于10'));
					} else {
						callback();
					}

				}, 1000);
			};
			//手机号
			const rulesloginId = (rule, value, callback) => {
				if (!/^1[345789]\d{9}$/.test(value)) {
					callback('请输入正确的手机号码')
				} else {
					callback()
				}
			};
			//身份证
			let validateCode =(rule, value, callback)=>{		
				if (!IdentityCodeValid(value)) {
						callback(new Error('请输入正确的身份证'));
				} else {
						callback();
				}				
			};
			return {
				pageLoading:false,
				stationList: [], //岗位
				pageTitle: "新增员工信息",
				fEmployeeID: '', //编辑员工的员工id
				carToolList: [],
				//fPositionIDs:[],//岗位id 多选数组
				addCarForm: {
					fEmployeeName: '', //姓名
					fPassWord:'',//密码
					fMobilephone: '', //手机号码
					// fIdentityNumber:'',//身份证号
					fStatus:true,//是否启用
					fRemark:'',
					fPositionIDs: [], //岗位id，多选数组
					codes:"",
					frv:"",
					rowStatus:"",
					fEmployeeID:""
				},
				// 验证码系列
				innerVisibles:false,
				codestatus: true,
				verCodeText: true, // 是否显示验证码字段
				msg:false,
				verCodeText: true, // 是否显示验证码字段
				verCodeNub: "", // 验证码读数
				verCodeTimer: null, // 验证码计时器变量
				// 验证码系列
				rules: {
					fEmployeeName: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					codes:[{
						required: true,
						message: '请输入短信验证码',
						trigger: 'blur'
					}],
					fPassWord:[{
						required: true,
						message: '请输入6-20位数字、字母或符号的组合',
						trigger: 'blur'
					},
					{
						required: true,
						//pattern: /^[A-Za-z0-9]{6,20}$/,
						pattern: /^([a-z0-9\.\@]){6,20}$/i,
						message: '请输入6-20位数字、字母或符号的组合',
						trigger: 'blur'
					}],
					fMobilephone: [{
							required: true,
							message: '请输入正确的手机号码',
							trigger: 'change',
						},{ validator: rulesloginId, trigger: 'blur' }

					],
					fEmployeeNumber: [{
						required: true,
						message: '请输入正确的年龄',
						trigger: 'blur'
					}],
					// fIdentityNumber:[{
					// 		required: true,
					// 		message: '请输入正确的身份证',
					// 		trigger: 'blur'
					// 	},
					// 	{ validator: validateCode, trigger: 'blur' }],
					fPositionIDs: [{
						required: true,
						message: '请选择岗位',
						trigger: 'change'
					}],
				}
			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			changeGraphic1
		},
		created() {
			this.pageTitle = this.$route.query.pageType;
			this.getTransportToolType();
			// this.getTransportToolType();
			if (this.pageTitle == "编辑员工信息") {
				this.fEmployeeID = this.$route.query.fEmployeeID;
				this.getEmployeeIdInform();
			}
		},
		methods: {
			// 获取验证码
			getCode() {
				let _this = this;
			
				if(_this.addCarForm.fEmployeeName == ""){
					// 请输入姓名
					return;
				}
				if(_this.addCarForm.fMobilephone == ""){
					// 手机号码为空
					return;
				}
				if(!/^1[345789]\d{9}$/.test(_this.addCarForm.fMobilephone)){
					// 请输入正确的手机号码
					return;
				}
				if(_this.msg == false){
					_this.innerVisibles = true;
					return;
				}
				if(_this.msg == true){
					_this.dialogVisible = false
					const TIME_COUNT = 60;
					if (!_this.verCodeTimer) {
						// TODO 临时用接口
						_this.ApiRequestPostNOMess("api/mall/sys/employee/employee-send-phone", {
								phone: _this.addCarForm.fMobilephone,
							}).then(
								(res) => {
									if(res.obj.status){
										// _this.tipsSuccess("验证码发送成功");
										_this.verCodeNub = TIME_COUNT;
										_this.verCodeText = false;
										_this.verCodeTimer = setInterval(() => {
											if (_this.verCodeNub > 0 && _this.verCodeNub <= TIME_COUNT) {
												_this.verCodeNub--;
											} else {
												_this.verCodeText = true;
												clearInterval(_this.verCodeTimer);
												_this.verCodeTimer = null;
												_this.msg = false;
											}
										}, 1000);
									}else{
										_this.tipsInfo('验证码发送频繁，请稍后再试！')
										_this.msg = false;
									}
								},
								(rej) => {
									_this.tipsInfo('验证码发送频繁，请稍后再试！')
									_this.msg = false;
								}
							);
					}
				}
			},
			// 关闭验证码
			closeYanzheng(){
				this.innerVisibles = false
			},
			//接收验证码传来的参数
			messageData(a) {
					if(a){
						// 验证正确
						var _this = this;
						setTimeout(function(){
							_this.innerVisibles = false;
							_this.msg = a
							_this.getCode();
							console.log('子传递值，已经变成true')
						}, 500);
						setTimeout(function(){
							
						},60000)
					}
			 },
			//查询所有启用岗位
			getTransportToolType() {
				this.ApiRequestPost('api/mall/sys/position/get-enable-detaile', {}).then(res => {
					this.stationList = res.obj.items;
					if(this.pageTitle != "编辑员工信息"){
						this.addCarForm.fPositionIDs.push(this.stationList[0].fPositionID)
					}
					if(res.obj == null || res.obj.length<0){}
				}, error => {

				});
			},
			//编辑员工信息，通过id查员工相关信息
			getEmployeeIdInform(){
				this.pageLoading = true;
				this.ApiRequestPost('api/mall/sys/employee/get', {
				id: this.fEmployeeID
				}).then(res => {
					this.pageLoading = false;
					this.addCarForm.fEmployeeName= res.obj.fEmployeeName //姓名
					this.addCarForm.fPassWord=res.obj.fPassWord//密码
					this.addCarForm.fMobilephone=res.obj.fMobilephone //手机号码
					// fIdentityNumber:'',//身份证号
					this.addCarForm.fStatus=res.obj.fStatus ==1 ? true : false;//是否启用
					// this.addCarForm.fRemark=''
					this.addCarForm.fPositionIDs=res.obj.fPositionID//岗位id，多选数组
					this.addCarForm.frv=res.obj.frv
					this.addCarForm.rowStatus=res.obj.rowStatus
					this.addCarForm.fEmployeeID=res.obj.fEmployeeID
					// this.addCarForm.codes=""	
					
					// this.addCarForm = res.obj;
					// this.addCarForm.fStatus = res.obj.fStatus == 1 ? true : false;//是否启用
					// this.addCarForm.fPositionIDs = res.obj.fPositionID; //岗位id，多选数组		
					// this.addCarForm.codes =  ""; //岗位id，多选数组	
				}, error => {
				this.pageLoading = false;
				});
			},
			handlestationType(val) {
				this.addCarForm.fPositionIDs = val;
			},
			submitForm(formName) {
				//console.log(this.addCarForm, "addCarFormaddCarForm")
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.pageTitle == "编辑员工信息") {
							this.ApiRequestPostNOMess('api/mall/sys/employee/chenk-employee-sms', {
								phone: this.addCarForm.fMobilephone,
								token: this.addCarForm.codes
							}).then(
								result => {
										if(result.status ==  true){
											this.pageLoading = true;
											this.addCarForm.fStatus = this.addCarForm.fStatus == true ? 1 : 0;
											this.addCarForm.rowStatus = 16;
											this.addCarForm.fEmployeeID = this.fEmployeeID;
											this.ApiRequestPut('api/mall/sys/employee/update', {
												"fEmployeeID":this.addCarForm.fEmployeeID,
												  "fPositionIDs":this.addCarForm.fPositionIDs,
												  "fEmployeeName":this.addCarForm.fEmployeeName,  //
												  "fMobilephone":this.addCarForm.fMobilephone,//
												  "fStatus":this.addCarForm.fStatus,//
												  "fRemark": this.addCarForm.fRemark,//
												  "fPassWord": this.addCarForm.fPassWord,//
												  "frv": this.addCarForm.frv,//
												  "rowStatus": this.addCarForm.rowStatus//
											}).then(
											result => {
													this.pageLoading = false;
													this.$router.push({
														path: '/PersonalContent/MyPerson',
														query: '',
													});
												},
												rej => {
													this.pageLoading = false;
													this.getEmployeeIdInform();
												}
											);
										}else{
											// 验证码错误
										}
								},
								rej => {
									this.pageLoading = false;
									// this.resetForm();
								}
							);
						} else if (this.pageTitle == "新增员工信息") {
							this.ApiRequestPostNOMess('api/mall/sys/employee/chenk-employee-sms', {
								phone: this.addCarForm.fMobilephone,
								token: this.addCarForm.codes
							}).then(
								result => {
									    console.log(result,"检查验证码");
										if(result.status ==  true){
											this.pageLoading = true;
											this.addCarForm.fStatus = this.addCarForm.fStatus == true ? 1 : 0;
											this.ApiRequestPost('api/mall/sys/employee/create', this.addCarForm).then(
												results => {
													if(results.message == "新增成功"){
														this.pageLoading = false;
														this.$router.push({
															path: '/PersonalContent/MyPerson',
															query: '',
														});
													}else{
														console.log('报错信息!')
													}
												},
												rej => {
													this.pageLoading = false;
													// this.resetForm();
												}
											);
											
										}else{
											// 验证码错误
										}
								},
								rej => {
									this.pageLoading = false;
									// this.resetForm();
								}
							);
							


						}


					} else {
						//console.log('error submit!!');
						return false;
					}
				});
			},
			//表单重置
			resetForm() {
				this.addCarForm = {
					fEmployeeName: '', //姓名
					fPassWord:'',//密码
					fMobilephone: '', //手机号码
					// fIdentityNumber:'',//身份证号
					fStatus:true,//是否启用
					fRemark:'',
					fPositionIDs: [], //岗位id，多选数组
					codes:''
				};

			}

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	
		.background-color-theme {
		  @include themify($themes) {
		    background-color: themed("themes_color");
		    color: themed("text_color_white");
		  }
		}
		
		

	.contBox {
		width: 100%;
		background-color: #FFFFFF;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		margin-left: 200px;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-title1 {
		width: 110px;
		height: 30px;
		background-color: #0173FE;
		border-radius: 2px;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
		// float:right;
	}
	.newPersonbtn-title{
		padding-left:12px;
		margin-top: 13px;
	}
	.newPersonbtn-Boxx {
		display: flex;
		justify-content: space-between;
		margin-right: 20px;
	}

	.newPersonbtn-Box {}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		width: 73px;
		height: 22px;
		color: #000000;

	}

	.el-button {
		width: 160px;
		height: 40px;
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}
	::v-deep .el-input__inner {
		height: 32px;
		line-height: 32px;
	}
	::v-deep .el-input__icon{
		line-height: 32px;
	}
</style>
